import React from 'react';
import styled from 'styled-components';
import { FooterSocialMedia } from '@hotelplan/components.common.footer';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import usePageFooter from 'components/domain/layout/usePageFooter';
import { FooterMenuBottomWrapper } from './Footer.styles';
import FooterMenuBottomDesktop from './FooterMenuBottomDesktop';
import PaymentMethods from './PaymentMethods';

const FooterContentWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1218px',
    width: '100%',
    mx: 'auto',
    px: 4,
    flexDirection: 'row',
    marginBottom: 5,
    height: '82px',
    '.social-media-title': {
      pl: 0,
      pr: 5,
      color: 'black',
      border: 'none',
    },
    '.social-media-wrapper': {
      border: 'none',

      a: {
        justifySelf: 'stretch',
        '&:not(:last-child)': {
          marginRight: 4,
        },
      },
    },
  })
);

const FooterBottomDesktop = () => {
  const {
    navigationMenu: links,
    socialMedia: socials,
    paymentMethods,
    brandLogos,
  } = usePageFooter();

  return (
    <>
      <FooterContentWrap>
        <FooterSocialMedia items={socials} className="social-media-wrapper" />
        <PaymentMethods items={paymentMethods} isDesktop />
      </FooterContentWrap>

      <FooterMenuBottomWrapper>
        <FooterMenuBottomDesktop links={links} brandLogos={brandLogos} />
      </FooterMenuBottomWrapper>
    </>
  );
};

export default FooterBottomDesktop;
