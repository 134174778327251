import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { ILinkProps, Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { IconMenuItem, Link as LinkObj, Maybe } from '@hotelplan/graphql.types';
import {
  getOpenMethod,
  getRouterLink,
} from '@hotelplan/libs.router-link-utils';

const PaymentsContainer = styled.div.attrs({
  'data-id': `payment-methods`,
})<{ isDesktop: boolean }>(({ theme: { space, colors }, isDesktop }) => ({
  display: 'flex',
  marginBottom: space[3],
  justifyContent: 'center',
  flexDirection: 'column',
  paddingLeft: space[3],
  paddingRight: space[3],
  ...(isDesktop
    ? {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 0,
        justifyContent: 'flex-start',
        paddingLeft: space[5],
        paddingRight: 0,
        borderLeft: '1px solid',
        borderColor: colors.borderColor,
      }
    : null),
}));

const PaymentsTitle = styled.span.attrs({
  'data-id': `title`,
})<{ isDesktop: boolean }>(({ theme: { colors }, isDesktop }) => ({
  color: colors.black,
  marginRight: '10px',
  fontSize: '19px',
  lineHeight: '28px',
  fontWeight: 500,
  paddingBottom: '10px',
  ...(isDesktop
    ? {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        display: 'block',
        paddingBottom: 0,
      }
    : null),
}));

interface IPaymentImageLinkProps extends ILinkProps {
  link?: Maybe<LinkObj>;
  isDesktop: boolean;
}

const PaymentImageLink = styled(Link)<IPaymentImageLinkProps>(
  ({ theme: { colors, radii, space, media }, isDesktop }) => ({
    fontSize: 0,
    height: '36px',
    width: '52px',
    border: '1px solid',
    borderColor: colors.lightGreyFirst,
    borderRadius: radii.default,
    textAlign: 'center',
    padding: '6px',
    letterSpacing: 0,
    transition: 'fast',
    '&:not(:last-child)': {
      [media.tablet]: isDesktop
        ? {
            marginRight: space[2],
          }
        : null,
    },
  }),
  ({ link, theme: { colors } }) =>
    link
      ? {
          '&:hover': {
            borderColor: colors.primary,
          },
        }
      : {}
);

const PaymentImage = styled(Image)({
  width: '100%',
  height: '100%',
  maxHeight: '30px',
  objectFit: 'contain',
});

const PaymentsList = styled.div<{ isDesktop: boolean }>(({ isDesktop }) =>
  isDesktop
    ? {}
    : {
        display: 'flex',
        gap: '10px',
      }
);

interface IFooterPaymentMethodsProps {
  items: IconMenuItem[];
  isDesktop?: boolean;
}

const PaymentMethods: React.FC<IFooterPaymentMethodsProps> = ({
  items,
  isDesktop,
}) => {
  const [t] = useTranslation('common');

  return (
    <PaymentsContainer isDesktop={isDesktop}>
      <PaymentsTitle isDesktop={isDesktop}>
        {t('payment.methods')}
      </PaymentsTitle>
      <PaymentsList isDesktop={isDesktop}>
        {items.map((item, index) => {
          const link = item.link ? getRouterLink(item.link) : undefined;
          return ((children: React.ReactNode): React.ReactNode =>
            link ? (
              <RouterLink key={`${item.iconId}_${index}`} {...link} passHref>
                {children}
              </RouterLink>
            ) : (
              children
            ))(
            <PaymentImageLink
              isDesktop={isDesktop}
              key={`link-${index}`}
              target={item.link ? getOpenMethod(item.link) : undefined}
              link={item.link}
              onClick={e => {
                if (!item.link) e.preventDefault();
              }}
            >
              <PaymentImage {...item.icon} />
              {item.caption}
            </PaymentImageLink>
          );
        })}
      </PaymentsList>
    </PaymentsContainer>
  );
};

export default PaymentMethods;
