import React from 'react';
import styled from 'styled-components';
import { BrandLogos } from '@hotelplan/components.common.brands-logos';
import { FooterCopyright } from '@hotelplan/components.common.footer';
import { LinksList } from '@hotelplan/components.common.links-list';
import Logo from '@hotelplan/components.common.logo/Logo';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FooterLogoLink } from './Footer.styles';
import { IFooterMenuLinksProps } from './Footer.types';

const FooterMenuWrapper = styled.div(({ theme: { colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    py: 5,
    gap: '32px',
    maxWidth: '1218px',
    width: '100%',
    mx: 'auto',
    px: 4,
    '.footer-copyright': {
      textAlign: 'center',
      pt: '10px',
      fontSize: 0,
      lineHeight: '18px',
      flexGrow: 1,
      order: 'initial',
      color: 'darkGray',
    },
    '.links-list': {
      borderTop: 'none',
      borderColor: 'lightGreySixth',
      px: 0,
      columnGap: 4,
      rowGap: 4,
      ml: 0,
      mb: 0,
      a: {
        fontSize: 1,
        lineHeight: '20px',
        textDecoration: 'underline',
        textDecorationColor: colors.linkUnderlineColor,
        textUnderlineOffset: '4px',
        transition: 'none',
        '&:hover,&:focus': {
          color: colors.defaultText,
          textDecorationColor: colors.defaultText,
          transform: 'scale(1)',
        },
      },
    },
    '.brand-logos-wrapper': {
      py: 0,
      borderColor: 'lightGreySixth',
    },
    '.brand-link': {
      height: '34px',
      p: 0,
    },
  })
);

const FooterLogo = styled(Logo)({
  width: '250px',
  height: '60px',
});

const FooterMenuBottom: React.FC<IFooterMenuLinksProps> = ({
  links,
  brandLogos,
}) => {
  return (
    <FooterMenuWrapper>
      <RouterLink href="/" passHref>
        <FooterLogoLink>
          <FooterLogo
            resized={[]}
            src="/images/hotelplan_logo.svg"
            alt="Hotel Plan Logo"
          />
        </FooterLogoLink>
      </RouterLink>
      <FooterCopyright />
      <LinksList links={links} />
      <BrandLogos brandLogos={brandLogos} className="brand-logos-wrapper" />
    </FooterMenuWrapper>
  );
};

export default FooterMenuBottom;
