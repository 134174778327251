import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Link } from '@hotelplan/components.common.link';

export const FooterMenuBottomWrapper = styled.div(
  sx2CssThemeFn({
    borderTop: '1px solid',
    borderColor: 'lightGreySixth',
  })
);

export const FooterLogoLink = styled(Link)({
  display: 'flex',
  width: '173px',
});
